import React from 'react';
// Import images
import packagingDesignLarge from '../../images/PACKAGING DESIGN & PRODUCTION 6.svg';
import packagingDesignTab from '../../images/packaging design & production tab.svg';
import packagingDesignMobile from '../../images/package design & production m.png';
import ServiceContactForm from './ServiceContactForm';
import AllServices from '../AllServices';
export default function PackageDesign() {
  return (
    <div>
      <div className="service-heading">
        <img src={packagingDesignLarge} width="100%" alt="image" className="lap" />
        <img src={packagingDesignTab} alt="" className="tab" width="100%" />
      </div>
      <div className="service-content">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div data-aos="fade-up" data-aos-duration="1000">
                <div className="service-para mt-3">
                <h2 className="content-main-heading mb-3">Package Design & Production</h2>
                  <p className="mt-3">
                    Packaging is one of the most fundamental activities when it comes to a product launch. Since packaging is the first moment of truth, it needs to be as effective as possible. At Nova Tales, our skilled team of designers ensures that they make an attractive product packaging design that sparks interest, provides relevant information, and starts a conversation. We strongly believe that packaging is crucial for brand identity as it creates the first connection between customers and the company and becomes a key part of brand recognition.
                  </p>
                  <p>We do innovative packaging design that has the potential to increase your brand value and boost sales. We ensure that our packaging combines form with function to create a thrilling experience for its users. packaging helps in creating an emotional connection between the brand and customer. It not only leaves a good impression on its user, but also effectively communicates important information about the product at a glance. </p>
                  <div className="media">
                    <div className="media-body">
                      <p>As a creative design agency, our packaging designs make a strong impact on store shelves and social media and makes customers naturally interested in the product. Whether it is creating a brand new packaging look or updating existing packaging, we got you covered.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="service-image">
                <div className="info-img position-relative">
                  <img src={packagingDesignMobile} alt="web-designing" className="img-fluid topBottom" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AllServices />
      <ServiceContactForm />
    </div>
  );
}

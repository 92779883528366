import React,{useEffect} from 'react';
import icon1 from '../images/icon 1.png';
import icon2 from '../images/icon 2.png';
import icon3 from '../images/icon3.png';
import icon4 from '../images/icon 4.png';
import MissionImage from '../images/SMM WEB IMAGE 2.png';
import AOS from 'aos';
import 'aos/dist/aos.css';
export default function Approach() {
    useEffect(() => {
        AOS.init(); // Initialize AOS
      }, []);
  return (
    <div>
      <div className="services">
        <div className="content-overlay">
          <div className="container">
            <div className="main-heading">
              <h2>Our <span>Approach</span> </h2>
              <p>
                Nova Tales is not just your marketing service provider. We are your partners in success, your creative collaborators, and your digital storytellers. Our approach is a fusion of creativity and data-driven strategy. We believe in the power of tailored solutions that connect with your audience, guided by analytics and fueled by creativity.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="box-background">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <div data-aos="fade-in">
                <div className="box">
                  <div className="icon">
                    <img src={icon1} alt="icon" width="90px" />
                  </div>
                  <div className="heading">
                    <p className="title">Holistic Approach</p>
                  </div>
                  <div className="content">
                    <p>Our process is all-encompassing. We start with a deep dive into your brand's essence and craft digital strategies that are in perfect alignment with your objectives.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div data-aos="fade-in">
                <div className="box">
                  <div className="icon">
                    <img src={icon2} alt="icon" width="90px" />
                  </div>
                  <div className="heading">
                    <p className="title">Innovation</p>
                  </div>
                  <div className="content">
                    <p>The digital landscape is ever-changing, and we are here to help you navigate it successfully. We are committed to keeping you updated with the latest trends and technologies.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div data-aos="fade-in">
                <div className="box">
                  <div className="icon">
                    <img src={icon4} alt="icon" width="90px" />
                  </div>
                  <div className="heading">
                    <p className="title">Results-Driven</p>
                  </div>
                  <div className="content">
                    <p>We do not just create and leave it at that. We continually measure, analyze, and optimize to ensure that your digital presence is performing at its best.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div data-aos="fade-in">
                <div className="box">
                  <div className="icon">
                    <img src={icon3} alt="icon" width="90px" />
                  </div>
                  <div className="heading">
                    <p className="title">Client-Centric</p>
                  </div>
                  <div className="content">
                    <p>Your satisfaction is our priority. We communicate transparently, keep you in the loop, and value your input throughout the process to ensure your contentment. </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="our">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-sm-12">
              <div data-aos="fade-in">
                <div className="effect">
                  <div className="our-heading">
                    <h3>
                      your Aims
                      <span className="wrapper">
                        our Expertise<div className="underline-animation">
                          <div className="spacer-left"></div>
                          <div className="underline"></div>
                          <div className="spacer-right"></div>
                        </div></span>
                    </h3>
                  </div>
                  <div className="goal">
                    <p>We are all about providing digital marketing services that match your goals perfectly. We want to boost your online presence and make your brand stand out. Whether it is creating interesting content, developing your marketing strategy or driving your digital ad campaigns, we have got you covered.</p>
                    <p>Our services are carefully crafted to make sure you make a big impact online. Join us for a journey to digital success, where our skills meet your vision, and our services lead the way to your marketing success.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-sm-12">
              <div className="our-image">
                <div data-aos="fade-in">
                  <img src={MissionImage} alt="mission" width="100%" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

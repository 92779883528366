import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import '../css/studio.css'; // Make sure you import your CSS file

// Import your images
import videoProductionLogo from '../images/video production logo.png';
import photoshootLogo from '../images/photoshoot logo.png';
import greenScreenTechnology from '../images/green csreen technology.png';
import studioRentals from '../images/STUDIO RENTALS.png';
import adFilms from '../images/ad films.png';
import youtubeAds from '../images/youtube ads.png';
import socialMediaMarketing from '../images/social m marketing icon N.png';
import productAds from '../images/product ads.png';
import corporateFilms from '../images/corporate films.png';
import explainerVideos from '../images/expainer videos.png';
import photoshootsImage from '../images/Photoshoots M.png';
import studioRentalsImage from '../images/Studio rentals M.png';


export default function Studio() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div className="studio">
      <div className="container">
        <div className="studio-heading">
          <h1>Create captivating videos that evoke a <span>meaningful response</span></h1>
        </div>
        <div className="section-images">
          <div className="section-tab">
            <a href="#">
              <h2 className="shadow"><img src={videoProductionLogo} alt="Video Production" width="40px" /> Video Production</h2>
            </a>
            <a href="#photoshoots">
              <h2 className="shadow"><img src={photoshootLogo} alt="Photoshoots" width="40px" /> Photoshoots</h2>
            </a>
          </div>
          <div className="section-tab">
            <a href="#green-screen">
              <h2 className="shadow"><img src={greenScreenTechnology} alt="Green Screen" width="50px" /> Green Screen</h2>
            </a>
            <a href="#studio-rentals">
              <h2 className="shadow"><img src={studioRentals} alt="Studio Rental" width="50px" /> Studio Rental</h2>
            </a>
          </div>
        </div>
      </div>
      <div className="video" id="video">
        <div className="container">
          <div className="section-title">
            <div data-aos="fade-up" data-aos-delay="0">
              <h2>VIDEO PRODUCTION</h2>
            </div>
          </div>
          <div className="section-para">
            <p>Our talented team of writers, graphic designers, artists, videographers, editors, musicians, directors and producers bring to life visually stunning video production assignments to suit your needs. </p>
          </div>
          <div className="video-icons">
            <div className="video-box">
              <div className="video-box-icon">
                <img src={adFilms} alt="Ad Films" width="70px" />
              </div>
              <div className="video-box-heading">
                <h3>Ad Films</h3>
              </div>
              <div className="video-box-content">
                <p>From storyboarding to post production for any medium of distribution </p>
              </div>
            </div>
            <div className="video-box">
              <div className="video-box-icon">
                <img src={youtubeAds} alt="YouTube Ads" width="80px" />
              </div>
              <div className="video-box-heading">
                <h3>YouTube Ads</h3>
              </div>
              <div className="video-box-content">
                <p>Captivating and visually stunning ads from 15 seconds up to 3 minutes</p>
              </div>
            </div>
            <div className="video-box">
              <div className="video-box-icon">
                <img src={socialMediaMarketing} alt="Social Media Ads" width="80px" />
              </div>
              <div className="video-box-heading">
                <h3>Social Media Ads</h3>
              </div>
              <div className="video-box-content">
                <p>Highly engaging, crisp, and optimized for Instagram and Facebook</p>
              </div>
            </div>
          </div>
          <div className="video-icons2">
            <div className="video-box">
              <div className="video-box-icon">
                <img src={productAds} alt="Product Videos" width="70px" />
              </div>
              <div className="video-box-heading">
                <h3>Product Videos</h3>
              </div>
              <div className="video-box-content">
                <p>Creative storytelling to increase customer engagement with product/service</p>
              </div>
            </div>
            <div className="video-box">
              <div className="video-box-icon">
                <img src={corporateFilms} alt="Corporate Films" width="60px" />
              </div>
              <div className="video-box-heading">
                <h3>Corporate Films</h3>
              </div>
              <div className="video-box-content">
                <p>Professionally done, ideal for external stakeholders & internal communication</p>
              </div>
            </div>
            <div className="video-box">
              <div className="video-box-icon">
                <img src={explainerVideos} alt="Explainer Videos" width="80px" />
              </div>
              <div className="video-box-heading">
                <h3>Explainer Videos</h3>
              </div>
              <div className="video-box-content">
                <p>Explain any new concept, product, service or app with simple ideas </p>
              </div>
            </div>
          </div>
          <div className="video-content">
            <p className="sub-video-heading">NovaTales production team creates relevant productions that maximize benefits:</p>
            <p> Our process for creating exceptional professional videos starts with first understanding your current business scenario and then doing our own in-depth research to align your business needs with the audience’s requirements. </p>
            <p>Our production team creates a detailed plan of timelines right from scripting to shots needed to create a smooth and efficient shooting schedule. Our creative team of directors and cinematographers makes sure the script is translated into spell-binding imagery through detailed instruction to the cast and crew and close monitoring of the progress. </p>
            <p>Our team can film at any location to get the best footage for your project. We can even transport you to a magical wonderland by shooting footage at our exclusive green screen studio and placing suitable backgrounds. Using the latest software, our skilled editors turn your footage into something amazing. </p>
            <p>Our post-production services include motion graphics, titles, animation, voiceover, special effects, music, sound design, and color adjustments. If you can think of it, we can do it. </p>
          </div>
        </div>
      </div>
      <div className="photoshoots" id="photoshoots">
        <div className="container">
          <div className="section-title">
            <div data-aos="fade-up" data-aos-delay="0">
              <h2>PHOTOSHOOTS</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="photo-content">
                <p>We pride ourselves on creating customized pictures that perfectly fit your brand and its objectives. From concept to execution we have the people, tools, the locations including our own exclusive studio and the process to supercharge your initiatives. We think out of the box to create unique solutions for every business need. Our photoshoots service delivers high-quality, visually stunning pictures that showcase your brand's values, products, and services. We work closely with you to understand your objectives and bring your vision to life.</p>
                <p>NovaTales Photoshoot service for various business needs such as visual content for webpages, photoshoots for Ad campaigns including search ads, displays ads, and social media ads, photoshoots for brochures, flyers and other marketing materials. We strategize, plan and execute every element of the photoshoot to be consistent with your Brand personality and fit into your overall Brand strategy. </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="photo-image">
                <img src={photoshootsImage} alt="Photoshoots" className="photoshoot" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="green-screen" id="green-screen">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="section-title">
                <div data-aos="fade-up" data-aos-delay="0">
                  <h2>GREEN SCREEN TECHNOLOGY</h2>
                </div>
              </div>
              <div className="green-content">
                <p>Green screen technology or green mat technology is called Chroma Key. It is used to replace backgrounds with digitally generated environments bringing impossible scenery to life allowing the incorporation of special effects, fantasy worlds and virtual sets, and significantly enhancing the visual experience.</p>
                <p>Nova Tales Green screen technology offers consistency in backgrounds, making them a valuable asset in commercial and e-commerce photography. By maintaining a standardized background, a uniform look can be achieved across a series of images, which is crucial for branding, marketing, and presenting products in online catalogues.</p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="section-title">
                <h2>Our exclusive Green Screen Studio</h2>
              </div>
              <div className="green-content exclusive">
                <p>Nova Tales green screen technology offers the opportunity for businesses to create visually stunning and impactful videos or photos such as Commercial videos, promotional videos, and digital advertisements. By filming in front of the vibrant green backdrop of our exclusive recording studio, our editors replace the background with any other existing backgrounds or computer-generated imagery during the post-production process which allows seamless integration of actors or presenters into dynamic environments. </p>
                <p>Green mat can be employed during live-streamed podcast recordings or video podcasts, providing an opportunity to insert branded backgrounds or interactive promotional videos that increases the overall viewer experience. This visual component can foster a stronger connection and potentially attract new audience through platforms that support video content.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="photoshoots" id="studio-rentals">
        <div className="container">
          <div className="section-title">
            <div data-aos="fade-up" data-aos-delay="0">
              <h2>STUDIO RENTALS</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <div className="photo-content">
                <p>The main advantage of shooting in a studio is of course the ability to control and share the quality of light. So, if you are in search for a well-equipped shooting space, studio sets designed for various types of shoots and a fine selection of posing aids, well, we have got everything you are looking for.</p>
                <p>Our studio is ready to serve various photography needs, whether you're a pro photographer, an aspiring artist, or part of a creative agency. We can accommodate different types of photography, such as fashion, product, portrait, e-commerce, commercial shoots, and more. We know that budget matters in creative projects, so our rental packages are affordable, letting you concentrate on your photography without spending too much.</p>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="rental-image">
                <img src={studioRentalsImage} alt="Studio Rentals" className="photoshoot" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

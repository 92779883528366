import React, {useEffect} from 'react';
import contentCreating from '../../images/content creating.svg';
import contentCreationTab from '../../images/content creation tab.svg';
import contentCreationWeb from '../../images/CONTENT CREATION WEB IMAGE.png';
import ServiceContactForm from './ServiceContactForm';
import AllServices from '../AllServices';
import AOS from 'aos';
import 'aos/dist/aos.css';
export default function ContentCreation() {
    useEffect(() => {
        AOS.init();
      }, []);
      useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  return (
    <div> 
      <div className="service-heading">
        <img src={contentCreating} width="100%" alt="image" className="lap" />
        <img src={contentCreationTab} alt="" className="tab" width="100%" />
      </div>
      <div className="service-content">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div data-aos="fade-up" data-aos-duration="1000">
                <div className="service-para mt-3">
                  <h2>Content Creation and stratagy</h2>
                  <p className="mt-3">
                    The content is a key component of an optimized page. We create content that is highly relevant, effective and engaging. We create content that make a real difference in the success of our client’s business. We drive change by creating unique narratives through articles, blog posts and videos about products and trends to establish our clients as industry leaders. Our focus is to boost revenue by generating content that leads to conversions.
                  </p>
                  <div className="media">
                    <div className="media-body">
                      <p>Our content marketing services include </p>
                      <h4>Text content</h4>
                      <p>
                        We create Long-form content, short-form content and blog posts which are tailored to your specific needs.
                      </p>
                    </div>
                  </div>
                  <div className="media">
                    <div className="media-body">
                      <h4>Audio</h4>
                      <p>
                        We strategize and create audio podcasts, audio books and sponsored audio content along with striking posts for organic following and engagement.
                      </p>
                    </div>
                  </div>
                  <div className="media">
                    <div className="media-body">
                      <h4>Video</h4>
                      <p>
                        We create video ads with interesting narratives to increase your following using content that makes audience want to like, comment and share.
                      </p>
                    </div>
                  </div>
                  <p>
                    Content marketing is a smart way of promoting products/services by sharing helpful and SEO-friendly content on different online platforms such as your website or blog, Instagram, Face book, YouTube, Hub Spot and more to encourage customers to take actions that benefit your business.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="service-image">
                <div className="info-img position-relative">
                  <img src={contentCreationWeb} alt="web-designing" className="img-fluid topBottom" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AllServices />
      <ServiceContactForm />
    </div>
  );
}

import React from 'react';
import { ReactTyped as Typed } from 'react-typed';
import 'animate.css'; // Ensure you have animate.css for animations
import '../css/Navbar.css';
import '../css/HomeMediaQuery.css'
import DigitalMarketing from '../images/digital illustration web.png'
const Section = () => {
  return (
    <section>
      <div className="container vh-100">
        <div className="row vh-100">
          <div className="col-lg-6 col-md-6 col-sm-12 d-flex flex-column justify-content-center align-items-center display-1 fw-semibold">
            <div className="main-text">
              <h1>
              <Typed
                  strings={['Empower', 'Transform', 'Elevate']}
                  typeSpeed={50}
                  backSpeed={80}
                  loop
                  className="txt-type" 
                />
                <div className="text">Digital Presence.</div>
              </h1>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="image animate__animated animate__fadeIn">
              <img src={DigitalMarketing} alt="Digital-Marketing-agency-in-hyderabd-Image" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Section;

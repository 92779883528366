import React, { useState } from 'react';
import ContactImage from '../images/women illustration.png';
import '../css/Navbar.css';
import '../css/HomeMediaQuery.css'
import emailjs from 'emailjs-com';
export default function ContactForm() {
  const [formData, setFormData] = useState({
    fullname: '',
    email: '',
    message: '',
    phonenumber: '',
    websiteurl: '',

  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      service_id: 'service_2kn5gwh',
      template_id: 'template_04ya5ba',
      user_id: 'Xd5Twe19AisS0_kkD',
      template_params: {
        from_name: formData.fullname,
        from_email: formData.email,
        phonenumber: formData.phonenumber,
        websiteurl: formData.websiteurl,
        to_name: 'Novatales Media',
        message: formData.message,
      },
    };
    try {
      const response = await fetch(
        'https://api.emailjs.com/api/v1.0/email/send',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        }
      );
      if (!response.ok) {
        throw new Error('Failed to send email');
      }
      alert('Your mail is sent!');
      console.log('Email sent successfully:', response);
      console.log(formData);
      // Optionally: Clear the form after successful submission
      setFormData({
        fullname: '',
        email: '',
        message: '',
        phonenumber: '',
        websiteurl: '',
      });
    } catch (error) {
      console.error('Error sending email:', error);
    }
  };


  return (
    <div>
      <div id="contact-us">
        <div className="row">
          <div className="col-lg-6 col-sm-12">
            <div className="contact-image">
              <img src={ContactImage} alt="contact-us" width="100%" />
            </div>
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="contact">
              <div className="contact-heading">
                <h2>Contact Us</h2>
              </div>
              <div className="contact-form">
                <form id="FrmEnquiry" onSubmit={handleSubmit}>
                  <input
                    name="fullname"
                    id="fullname"
                    required
                    placeholder="Your Name"
                    value={formData.fullname}
                    onChange={handleChange}
                  /><br />
                  <input
                    name="email"
                    id="email"
                    type="email"
                    required
                    placeholder="Your Email"
                    value={formData.email}
                    onChange={handleChange}
                  /><br />
                  <input
                    type="tel"
                    name="phonenumber"
                    id="phonenumber"
                    required
                    value={formData.phonenumber}
                    placeholder="Your Phone Number"
                    onChange={handleChange}
                  /><br />
                  <input
                    type="URL"
                    name="websiteurl"
                    id="websiteurl"
                    value={formData.websiteurl}
                    placeholder="Your website URL"
                    onChange={handleChange}
                  /><br />
                  <div className="clearfix"></div>
                  <div className="message">
                    <textarea
                      name="message"
                      id="message"
                      required
                      placeholder="Message"
                      value={formData.message}
                      onChange={handleChange}
                    ></textarea>
                  </div>
                  <div className="submit">
                    <input id="submit" type="submit" value="Submit" className="btn pink" />
                  </div>
                </form>
                <span id="successMessage"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from 'react';
import RocketImage from '../../images/rocket 2-01.png';
import Cloud1Image from '../../images/rocket 3-01.png';
import Cloud2Image from '../../images/IMG_20231227_151842.png';
import '../../css/servisesCss/ServiceContact.css'
import AOS from 'aos';
import 'aos/dist/aos.css';
export default function ServiceContactForm() {

  const [formData, setFormData] = useState({
    email: '',
    websiteurl: '',
    fullname: '',
    phonenumber: '',
     message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      service_id: 'service_2kn5gwh',
      template_id: 'template_04ya5ba',
      user_id: 'Xd5Twe19AisS0_kkD',
      template_params: {
        from_name: formData.fullname,
        from_email: formData.email,
        to_name: 'Novatales Media',
        phonenumber: formData.phonenumber,
        websiteurl: formData.websiteurl,
        message: formData.message,
      },
    };

    try {
      const response = await fetch('https://api.emailjs.com/api/v1.0/email/send', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error('Failed to send email');
      }

      alert('Your mail is sent!');
      console.log('Email sent successfully:', response);

      // Clear the form after successful submission
      setFormData({
        email: '',
        websiteurl: '',
        fullname: '',
        phonenumber: '',
        message: '',
      });
    } catch (error) {
      console.error('Error sending email:', error);
    }
  };
  useEffect(() => {
    AOS.init();
  }, []);


  return (
    <div>
      <div className="service-contact">
        <div className="contact-now">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div data-aos="fade-up">
                  <img src={RocketImage} alt="" width="550px" className="rocket" />
                </div>

                <h4> Fill out the form and one of our digital marketing experts will get in touch with you.</h4>
                <div data-aos="fade-up">
                  <img src={Cloud1Image} alt="" width="700px" className="cloud1" />
                  <img src={Cloud2Image} alt="" className="cloud2" width="700px" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="service-contact-us">
                  <form onSubmit={handleSubmit}>
                    <input
                      type="text"
                      name="fullname"
                      id="fullname"
                      required
                      value={formData.fullname}
                      onChange={handleChange}
                      placeholder="Your Full Name"
                    /><br />
                    <input
                      type="email"
                      name="email"
                      id="email"
                      required
                      aria-required="true"
                      pattern="^.+@.+\.[a-zA-Z]{2,63}$"
                      maxLength="250"
                      value={formData.email}
                      onChange={handleChange}
                      placeholder="Email Address"
                    /><br />
                    <input
                      type="tel"
                      name="phonenumber"
                      id="phonenumber"
                      required
                      value={formData.phonenumber}
                      onChange={handleChange}
                      placeholder="Phone Number"
                    /><br />
                    <input
                      type="URL"
                      name="websiteurl"
                      id="websiteurl"
                      value={formData.websiteurl}
                      onChange={handleChange}
                      placeholder="Your Website URL"
                    /><br />
                    <textarea
                      name="message"
                      id="message"
                      required
                      placeholder="Message"
                      value={formData.message}
                      onChange={handleChange}
                    ></textarea>
                    <input
                      id="submit"
                      type="submit"
                      value="REQUEST A QUOTE NOW"
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

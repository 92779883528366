import React , { useEffect}from 'react';
import Banner from './Banner';
import Approach from './Approach';
import OurServices from './OurServices';
import ContactForm from './ContactForm';

const Home = () => {
  useEffect(()=>{
    window.scrollTo(0,0)
   })
  return (
    <div>
      <Banner />
      <Approach />
      <OurServices />
      <ContactForm />
    </div>
  );
};

export default Home;

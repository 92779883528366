import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import NotFound from './components/NotFound';
import About from './components/About'; // Import About component or any other page components
import Header from './components/Header';
import Footer from './components/Footer';
import './css/HomeMediaQuery.css'; // Ensure your global styles are imported
import Studio from './components/Studio';
import Technologies from './components/Technologies';
import ContactUsMain from './components/ContactUsMain';
import BrandingStrategy from './components/Services/BrandingStrategy';
import BrandPositioning from './components/Services/BrandPositioning';
import LogoVisual from './components/Services/LogoVisual';
import PackageDesign from './components/Services/PackageDesign';
import Advertising from './components/Services/Advertising';
import Seo from './components/Services/Seo';
import SMM from './components/Services/SMM';
import Analytics from './components/Services/Analytics';
import LogoQuestions from './components/Services/LogoQuestions';
import PaidAdvertising from './components/Services/PaidAdvertising';
import EmailMarketing from './components/Services/EmailMarketing';
import WebDesigning from './components/Services/WebDesigning';
import MobileApp from './components/Services/MobileApp';
import Ecommerce from './components/Services/Ecommerce';
import ContentCreation from './components/Services/ContentCreation';
import Podcast from './components/Services/Podcast';
import DigitalMarketing from './components/Services/DigitalMarketing';

const App = () => {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/studio" element={<Studio />} />
        <Route path="/technologies" element={<Technologies />} />
        <Route path="/ContactUs" element={<ContactUsMain />} />
        <Route path="*" component={NotFound} />
                {/* Services Routes */}

        <Route path="/Branding-strategy-and-Planning" element={<BrandingStrategy />} />
        <Route path="/Brand-Positioning" element={<BrandPositioning />} />
        <Route path="/Logo-and-visual-elements" element={<LogoVisual />} />
        <Route path="/logo-question" element={<LogoQuestions />} />
        <Route path="/Package-Design" element={<PackageDesign />} />
        <Route path="/Advertising-and-Marketing-Materials" element={<Advertising />} />
        <Route path="/Digital-Marketing" element={<DigitalMarketing />} />
        <Route path="/Search-Engine-optmization" element={<Seo />} />
        <Route path="/Social-Media-Marketing" element={<SMM />} />
        <Route path="/Analytics-Business-Insights" element={<Analytics />} />
        <Route path="/Paid-advertising" element={<PaidAdvertising />} />
        <Route path="/Email-Marketing" element={<EmailMarketing />} />
        <Route path="/UI-Ux-Design" element={<WebDesigning />} />
        <Route path="/Mobile-App-Development" element={<MobileApp />} />
        <Route path="/Ecommerce" element={<Ecommerce />} />
        <Route path="/Content-Creation-And-Strategy" element={<ContentCreation />} />
        <Route path="/Podcast-Planning-And-Production" element={<Podcast />} />
      </Routes>
      <Footer />
    </Router>
  );
};

export default App;

import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import '../css/technologies.css';

// Import your images
import flutter from '../images/flutter-1.svg';
import angular from '../images/angular-2.svg';
import reactJs from '../images/react-js.svg';
import vue from '../images/vue-1.png';
import nextjs from '../images/nextjs.png';
import nodeJs from '../images/node-js.svg';
import mongoDb from '../images/mongo-db-1.svg';
import python from '../images/python-2.svg';
import golang from '../images/golang.svg';
import firebase from '../images/firebase-1.svg';
import aws from '../images/aws-1.svg';
import googleCloud from '../images/google-cloud.svg';
import zoho from '../images/zoho.svg';
import salesforce from '../images/salesforce.svg';
import paypal from '../images/paypal.svg';
import googleAnalytics from '../images/google-analytics.svg';
import postman from '../images/postman.svg';
import { Link } from 'react-router-dom';

export default function Technologies() {
  useEffect(() => {
    AOS.init();
  }, []);
 useEffect(()=>{
  window.scrollTo(0,0)
 })
  return (
    <div className="technologies">
      <div className="container">
        <div className="technologies-heading">
          <h1>Visualize the future and make it a reality with our <span>software technologies</span></h1>
          <div className="lets">
            <button className="talk"><Link to="/ContactUs">Let's talk</Link></button>
            <button className="service-button" data-aos="fade-up" data-aos-delay="0"><Link to="/">Our Services</Link></button>
          </div>
        </div>
      </div>

      <div className="frontend">
        <div className="container">
          <div className="section-title">
            <div data-aos="fade-up" data-aos-delay="0">
              <h2>Front End Technologies</h2>
            </div>
          </div>
          <div className="section-para">
            <p>Connect with a broad audience with the help of robust web applications developed using flexible and multi-platform technologies such as Angular, React JS, Vue.JS, and Flutter.</p>
          </div>
          <div className="section-images">
            <img src={flutter} alt="Flutter" width="240px" height="120px" className="image-cover" />
            <img src={angular} alt="Angular" width="240px" height="120px" className="image-cover" />
            <img src={reactJs} alt="React js" width="240px" height="120px" className="image-cover" />
            <img src={vue} alt="vuejs" width="240px" height="120px" className="image-cover" />
            <img src={nextjs} alt="Nextjs" width="240px" height="120px" className="image-cover" />
          </div>
        </div>
      </div>

      <div className="backend">
        <div className="container">
          <div className="section-title">
            <div data-aos="fade-up" data-aos-delay="0">
              <h2>Back End Technologies</h2>
            </div>
          </div>
          <div className="section-para">
            <p>Build a strong base for your mobile app or online platform using powerful, adaptable backend technologies and ensure a seamless experience for your users globally. We have expertise in deploying server-side languages such as PHP, Java, Python, NodeJS, and MongoDB.</p>
          </div>
          <div className="section-images">
            <img src={nodeJs} alt="Nodejs" width="240px" height="120px" className="image-cover" />
            <img src={mongoDb} alt="MongoDB" width="240px" height="120px" className="image-cover" />
            <img src={python} alt="Python" width="240px" height="120px" className="image-cover" />
            <img src={golang} alt="Golang" width="240px" height="120px" className="image-cover" />
            <img src={firebase} alt="Firebase" width="240px" height="120px" className="image-cover" />
          </div>
        </div>
      </div>

      <div className="cloud-tech">
        <div className="container">
          <div className="section-title">
            <div data-aos="fade-up" data-aos-delay="0">
              <h2>Cloud Technologies</h2>
            </div>
          </div>
          <div className="section-para">
            <p>Lead the way in the cloud computing market. Our expertise in cloud technologies like Amazon Web Services and Google Cloud are tailor-made to fit your business needs.</p>
          </div>
          <div className="section-images">
            <img src={aws} alt="Aws" width="240px" height="120px" className="image-cover" />
            <img src={googleCloud} alt="Google Cloud" width="240px" height="120px" className="image-cover" />
          </div>
        </div>
      </div>

      <div className="software">
        <div className="container">
          <div className="section-title">
            <div data-aos="fade-up" data-aos-delay="0">
              <h2>Software Integrations</h2>
            </div>
          </div>
          <div className="section-para">
            <p>Nova Tales is your go-to solution for effortlessly integrating third-party softwares such as ZOHO, Zendesk, Analytics, Firebase, Paypal, and more into your current IT platform, ensuring disruption-free operations.</p>
          </div>
          <div className="section-images">
            <img src={zoho} alt="ZOHO" width="240px" height="120px" className="image-cover" />
            <img src={firebase} alt="Firebase" width="240px" height="120px" className="image-cover" />
            <img src={salesforce} alt="Salesforce" width="240px" height="120px" className="image-cover" />
            <img src={paypal} alt="Paypal" width="240px" height="120px" className="image-cover" />
            <img src={googleAnalytics} alt="Google Analytics" width="240px" height="120px" className="image-cover" />
          </div>
        </div>
      </div>

      <div className="testing">
        <div className="container">
          <div className="section-title">
            <div data-aos="fade-up" data-aos-delay="0">
              <h2>Testing & QA</h2>
            </div>
          </div>
          <div className="section-para">
            <p>Our proven Testing and QA solutions help our clients release fully integrated, tested, and user-friendly solutions for their users, free from bugs and glitches.</p>
          </div>
          <div className="section-images">
            <img src={postman} alt="Postman" width="240px" height="120px" className="image-cover" />
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useState, useEffect } from 'react';
import { Navbar, Nav, NavDropdown, Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faFacebookSquare, faTwitterSquare, faLinkedin, faYoutubeSquare } from '@fortawesome/free-brands-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import Logo from '../images/NOVATALES F3.png';
import { Link, useLocation } from 'react-router-dom';
import '../css/Navbar.css';

const Header = () => {
  
  const [showDropdown, setShowDropdown] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [currentPage, setCurrentPage] = useState(localStorage.getItem('currentPage') || 'home');
  const handleMouseEnter = () => {
    setShowDropdown(true);
  };

  const handleMouseLeave = () => {
    setShowDropdown(false);
  };

  const handleNavCollapse = () => {
    setExpanded(false);
  };
  const handleNavigation = (page) => {
    setCurrentPage(page);
        localStorage.setItem('currentPage', page);
  };

  useEffect(() => {
    const handleScroll = () => {
      const header = document.querySelector('header');
      if (window.scrollY > 0) {
        header.classList.add('scrolled');
        setIsScrolled(true);
      } else {
        header.classList.remove('scrolled');
        setIsScrolled(false);
      }
    };

    const handleClickOutside = (event) => {
      const navbar = document.querySelector('.navbar');
      if (navbar && !navbar.contains(event.target)) {
        setExpanded(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    document.addEventListener('click', handleClickOutside);

    // Clean up the event listeners
    return () => {
      window.removeEventListener('scroll', handleScroll);
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);


  return (
    <header className={`menubar ${currentPage} ${isScrolled ? 'scrolled' : ''}`}>
      <Navbar expand="lg" className="navbar" expanded={expanded}>
        <Container fluid>
          <Navbar.Brand as={Link} to='/' className='Logo'>
            <img src={Logo} alt="Logo" width="100px" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarSupportedContent" onClick={() => setExpanded(expanded ? false : true)} />
          <Navbar.Collapse id="navbarSupportedContent">
            <Nav className="ms-auto">
              <Nav.Link as={Link} to="/" onClick={() => { handleNavigation('home'); handleNavCollapse(); }}>Home</Nav.Link>
              <Nav.Link as={Link} to="/about" onClick={() => { handleNavigation('about'); handleNavCollapse(); }}>About</Nav.Link>
              <Nav.Link as={Link} to="/studio" onClick={() => { handleNavigation('about'); handleNavCollapse(); }}>Studio</Nav.Link>
              <NavDropdown
                title="Services"
                id="navbarScrollingDropdown"
                show={showDropdown}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <div className="box-down usermenudropdown">
                  <div className="row">
                    <div className="col-xl-4 col-lg-4 col-sm-6 mx-auto">
                      <h2><NavDropdown.Item as={Link} to="/Branding-strategy-and-Planning" onClick={() => { handleNavigation('about'); handleNavCollapse(); }}>Branding</NavDropdown.Item></h2>
                      <ul>
                        <NavDropdown.Item as={Link} to="/Branding-strategy-and-Planning" onClick={() => { handleNavigation('about'); handleNavCollapse(); }}>Branding strategy & Planning</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/Brand-Positioning" onClick={() => { handleNavigation('about'); handleNavCollapse(); }}>Brand Positioning</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/Logo-and-visual-elements" onClick={() => { handleNavigation('about'); handleNavCollapse(); }}>Logo & Visual Elements</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/logo-question" onClick={() => { handleNavigation('about'); handleNavCollapse(); }}>LOGO DESIGN QUESTIONNAIRE</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/Package-Design" onClick={() => { handleNavigation('about'); handleNavCollapse(); }}>Packaging Design & Production</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/Advertising-and-Marketing-Materials" onClick={() => { handleNavigation('about'); handleNavCollapse(); }}>Advertising & Marketing Materials</NavDropdown.Item>
                      </ul>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-sm-6 mx-auto">
                      <h2><NavDropdown.Item as={Link} to="/Digital-Marketing" onClick={() => { handleNavigation('about'); handleNavCollapse(); }}>Digital Marketing</NavDropdown.Item></h2>
                      <ul>
                        <NavDropdown.Item as={Link} to="/Search-Engine-optmization" onClick={() => { handleNavigation('about'); handleNavCollapse(); }}>Search Engine Optimization</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/Social-Media-Marketing" onClick={() => { handleNavigation('about'); handleNavCollapse(); }}>Social media marketing</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/Analytics-Business-Insights" onClick={() => { handleNavigation('about'); handleNavCollapse(); }}>Analytics & Business Insights</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/Paid-advertising" onClick={() => { handleNavigation('about'); handleNavCollapse(); }}>Paid Advertising</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/Email-Marketing" onClick={() => { handleNavigation('about'); handleNavCollapse(); }}>Email marketing</NavDropdown.Item>
                      </ul>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-sm-12 mx-auto">
                      <div className="row">
                        <div className="col-lg-12 col-md-6">
                          <h2><NavDropdown.Item as={Link} to="/UI-Ux-Design" onClick={() => { handleNavigation('about'); handleNavCollapse(); }}>Web & App Development</NavDropdown.Item></h2>
                          <ul>
                            <NavDropdown.Item as={Link} to="/UI-Ux-Design" onClick={() => { handleNavigation('about'); handleNavCollapse(); }}>UI / UX Design</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/Mobile-App-Development" onClick={() => { handleNavigation('about'); handleNavCollapse(); }}>Mobile App Development</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/Ecommerce" onClick={() => { handleNavigation('about'); handleNavCollapse(); }}>Ecommerce</NavDropdown.Item>
                          </ul>
                        </div>
                        <div className="col-lg-12 col-md-6">
                          <h2><NavDropdown.Item as={Link} to="/Content-Creation-And-Strategy" onClick={() => { handleNavigation('about'); handleNavCollapse(); }}>Content Marketing</NavDropdown.Item></h2>
                          <ul>
                            <NavDropdown.Item as={Link} to="/Content-Creation-And-Strategy" onClick={() => { handleNavigation('about'); handleNavCollapse(); }}>Content creation and strategy</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/Podcast-Planning-And-Production" onClick={() => { handleNavigation('about'); handleNavCollapse(); }}>Podcast planning & Production</NavDropdown.Item>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </NavDropdown>
              <Nav.Link as={Link} to="/technologies" onClick={() => { handleNavigation('about'); handleNavCollapse(); }}>Technologies</Nav.Link>
              <Nav.Link as={Link} to="/ContactUs" onClick={() => { handleNavigation('about'); handleNavCollapse(); }}>Contact</Nav.Link>
            </Nav>
            <div className="d-flex ms-auto">
              <div className="social-icons">
                <div className="row">
                  <div className="col-2">
                    <Link to="https://www.instagram.com/novatalesmedia/" target="_blank" rel="noopener noreferrer">
                      <FontAwesomeIcon icon={faInstagram} className="fa-brands" />
                    </Link>
                  </div>
                  <div className="col-2">
                    <Link to='https://www.facebook.com/Novatalesmedia/' target="_blank" rel="noopener noreferrer">
                      <FontAwesomeIcon icon={faFacebookSquare} className="fa-brands" />
                    </Link>
                  </div>
                  <div className="col-2">
                    <Link to="https://twitter.com/novatalesmedia" target="_blank" rel="noopener noreferrer">
                      <FontAwesomeIcon icon={faTwitterSquare} className="fa-brands" />
                    </Link>
                  </div>
                  <div className="col-2">
                    <Link to="https://www.linkedin.com/company/novatales/" target="_blank" rel="noopener noreferrer">
                      <FontAwesomeIcon icon={faLinkedin} className="fa-brands" />
                    </Link>
                  </div>
                  <div className="col-2">
                    <Link to="https://www.youtube.com/@NovaTalesMedia" target="_blank" rel="noopener noreferrer">
                      <FontAwesomeIcon icon={faYoutubeSquare} className="fa-brands" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

export default Header;

import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot, faPhoneVolume, faEnvelope, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { faSquareInstagram, faFacebookSquare, faSquareXTwitter, faLinkedin, faYoutubeSquare } from '@fortawesome/free-brands-svg-icons';
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import Logo from '../images/NOVATALES F3.png';
import '../css/Navbar.css';
import '../css/HomeMediaQuery.css'
export default function Footer() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    // useEffect(() => {
    //     const script = document.createElement('script');
    //     script.async = true;
    //     script.src = 'https://d2mpatx37cqexb.cloudfront.net/delightchat-whatsapp-widget/embeds/embed.min.js';
    //     script.onload = () => {
    //       const wa_btnSetting = {
    //         "btnColor": "rgb(254 196 54)",
    //         "ctaText": "",
    //         "cornerRadius": 40,
    //         "marginBottom": 20,
    //         "marginLeft": 20,
    //         "marginRight": 20,
    //         "btnPosition": "right",
    //         "whatsAppNumber": "+919459666999",
    //         "welcomeMessage": "",
    //         "zIndex": 999999,
    //         "btnColorScheme": "dark"
    //       };
    //       window._waEmbed(wa_btnSetting);
    //     };
    //     document.body.appendChild(script);
    //     return () => {
    //       document.body.removeChild(script);
    //     };
    //   }, []);
    return (
        <div>
            <div className="our-address">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-6">
                            <div className="about">
                                <h2>About</h2>
                                <p>
                                    <Link to="/about">Nova Tales is a boutique digital marketing and brand strategy consulting firm with expertise in Marketing campaign strategy, Multimedia content creation, Web and App development, Podcasting etc.</Link>
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="footer-address">
                                <div className="address-heading">
                                    <h2>Address</h2>
                                </div>
                                <div className="address">
                                    <a href="https://maps.app.goo.gl/G452GLT5UuVHm3KaA" target="_blank" rel="noopener noreferrer">
                                        <FontAwesomeIcon icon={faLocationDot} />
                                        <p>Endula Empresa, 5th Floor, <br />Survey no : 192, Masjid Banda, Kondapur, Hyderabad.</p>
                                    </a>
                                </div>
                                <div className="call">
                                    <a href="tel:+91 9459666999" className="ui-link">
                                        <FontAwesomeIcon icon={faPhoneVolume} />
                                        <p>+91 9459666999</p>
                                    </a>
                                </div>
                                <div className="email">
                                    <a href="mailto:info@novatales.com">
                                        <FontAwesomeIcon icon={faEnvelope} />
                                        <p>info@novatales.com</p>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="our-links">
                                <div className="links-heading">
                                    <h2>Services</h2>
                                </div>
                                <ul>
                                    <li><Link to="/Branding-strategy-and-Planning"><FontAwesomeIcon icon={faArrowRight} className="fa-solid" /> Branding</Link></li>
                                    <li><Link to="/Digital-Marketing"><FontAwesomeIcon icon={faArrowRight} className="fa-solid" /> Digital Marketing</Link></li>
                                    <li><Link to="/UI-Ux-Design"><FontAwesomeIcon icon={faArrowRight} className="fa-solid" /> Web & App Development</Link></li>
                                    <li><Link to="/Content-Creation-And-Strategy"><FontAwesomeIcon icon={faArrowRight} className="fa-solid" /> Content Marketing</Link></li>
                                    <li><Link to="/studio"><FontAwesomeIcon icon={faArrowRight} className="fa-solid" /> Visual Solutions</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-6">
                            <div className="brands">
                                <div className="brand-heading">
                                    <h2>Follow Us</h2>
                                </div>
                                <div className="row">
                                    <div className="col-1 pr-3">
                                        <Link to="https://www.instagram.com/novatalesmedia/" title="Instagram" target="_blank" rel="noopener noreferrer">
                                            <FontAwesomeIcon icon={faSquareInstagram} size="2x" />
                                        </Link>
                                    </div>
                                    <div className="col-1 pr-3">
                                        <Link to="https://www.facebook.com/Novatalesmedia/" title="Facebook" target="_blank" rel="noopener noreferrer">
                                            <FontAwesomeIcon icon={faFacebookSquare} size="2x" />
                                        </Link>
                                    </div>
                                    <div className="col-1 pr-3">
                                        <Link to="https://twitter.com/novatalesmedia" title="Twitter" target="_blank" rel="noopener noreferrer">
                                            <FontAwesomeIcon icon={faSquareXTwitter} size="2x" />
                                        </Link>
                                    </div>
                                    <div className="col-1 pr-3">
                                        <Link to="https://www.linkedin.com/company/novatales/" title="LinkedIn" target="_blank" rel="noopener noreferrer">
                                            <FontAwesomeIcon icon={faLinkedin} size="2x" />
                                        </Link>
                                    </div>
                                    <div className="col-1 pr-3">
                                        <Link to="https://www.youtube.com/@NovaTalesMedia" title="YouTube" target="_blank" rel="noopener noreferrer">
                                            <FontAwesomeIcon icon={faYoutubeSquare} size="2x" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FloatingWhatsApp
                phoneNumber="+919459666999"
                accountName="Novatales Media"
                chatMessage="Welcome to Novatales Media! How can we help you?"
                avatar={Logo}
            />
            <div className="copy">
                <h6>&copy; 2023 CopyRights: NovaTales.com</h6>
            </div>
        </div>
    );
}

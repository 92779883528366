import React, { useEffect, useState } from 'react';
import '../css/contactus-main.css'
import logo from '../images/NOVATALES F3.png'; 
import { Link } from 'react-router-dom';
export default function ContactUsMain() {
  const [formData, setFormData] = useState({
    fullname: '',
    email: '',
    companyName: '',
    service: '',
    scopeOfWork: '',
    otherInfo: '',
  });

  useEffect(() => {
    const forms = document.getElementsByClassName('needs-validation');
    Array.prototype.filter.call(forms, (form) => {
      form.addEventListener('submit', (event) => {
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        }
        form.classList.add('was-validated');
      }, false);
    });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const form = e.target;
    if (form.checkValidity() === false) {
      e.stopPropagation();
      form.classList.add('was-validated');
      return;
    }
  
    const data = {
      service_id: 'service_2kn5gwh',
      template_id: 'template_lvs7233',
      user_id: 'Xd5Twe19AisS0_kkD',
      template_params: {
        from_name: formData.fullname,
        from_email: formData.email,
        to_name: 'Novatales Media',
        companyName: formData.companyName,
        service: formData.service,
        work: formData.scopeOfWork,
        otherInfo: formData.otherInfo,
      },
    };
  
    try {
      const response = await fetch('https://api.emailjs.com/api/v1.0/email/send', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
  
      if (!response.ok) {
        throw new Error('Failed to send email');
      }
  
      alert('Your mail is sent!');
      console.log('Email sent successfully:', response);
      console.log(formData);
  
      // Clear the form after successful submission
      setFormData({
        fullname: '',
        email: '',
        companyName: '',
        service: '',
        scopeOfWork: '',
        otherInfo: '',
      });
  
      // Reset validation state
      form.classList.remove('was-validated');
    } catch (error) {
      console.error('Error sending email:', error);
    }
  };
  

useEffect(() => {
  window.scrollTo(0, 0);
}, []);

  return (
    <div className="touch">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6">
            <div className="get-heading">
              <h1>Get in touch</h1>
            </div>
            <div className="contact">
              <form  className="needs-validation"  onSubmit={handleSubmit} noValidate>
                <div className="name">
                  <label htmlFor="name">Name</label> <br />
                  <input type="text" name="fullname" className="form-control" id="fullname" value={formData.fullname} onChange={handleChange} required  />
                  <div className="invalid-tooltip">
                    Name is Required.
                  </div>
                </div>
                <div className="company-name">
                  <label htmlFor="company_name">Company Name</label> <br />
                  <input type="text" name="companyName" id="companyName" value={formData.companyName} onChange={handleChange}/>
                </div>
                <div className="selection">
                  <label htmlFor="service"> Services Required</label> <br />
                  <select className="custom-select" name="service" id="service" onChange={handleChange} value={formData.service} required >
                    <option value="" selected>Select Service</option>
                    <option value="Branding">Branding</option>
                    <option value="Branding strategy & planning">Branding strategy & planning</option>
                    <option value="Branding positioning">Branding positioning</option>
                    <option value="Logo & Visual Elements">Logo & Visual Elements</option>
                    <option value="Packaging Design & Production">Packaging Design & Production</option>
                    <option value="Advertising & Marketing Materials">Advertising & Marketing Materials</option>
                    <option value="Digital Marketing">Digital Marketing</option>
                    <option value="Search Engine Optimization">Search Engine Optimization</option>
                    <option value="Social Media Marketing">Social Media Marketing</option>
                    <option value="Analytics & Business Insights">Analytics & Business Insights</option>
                    <option value="Paid Advertising">Paid Advertising</option>
                    <option value="Inbound Marketing">Inbound Marketing</option>
                    <option value="Web & App Development">Web & App Development</option>
                    <option value="Mobile App Development">Mobile App Development</option>
                    <option value="E-commerce">E-commerce</option>
                    <option value="Product Design">Product Design</option>
                    <option value="Conversion Rate Optimization">Conversion Rate Optimization</option>
                    <option value="Visual Solutions">Visual Solutions</option>
                    <option value="Video Production">Video Production</option>
                    <option value="Photoshoots">Photoshoots</option>
                    <option value="Studio Rentals">Studio Rentals</option>
                    <option value="Content Marketing">Content Marketing</option>
                    <option value="Content Creation & Strategy">Content Creation & Strategy</option>
                    <option value="Podcast Planning & Production">Podcast Planning & Production</option>
                  </select>
                  <div className="invalid-tooltip">
                    Please choose a Service.
                  </div>
                </div>
                <div className="scope">
                  <label htmlFor="scope_of_work">Scope of Work</label> <br />
                  <textarea name="scopeOfWork" id="scopeOfWork" cols="30" rows="5" className="form-control" value={formData.scopeOfWork} onChange={handleChange} required></textarea>
                  <div className="invalid-tooltip">
                    Scope of work is Required.
                  </div>
                </div>
                <div className="email">
                  <label htmlFor="email">E-mail Address</label> <br />
                  <input type="email" name="email" className="form-control" id="email" value={formData.email} onChange={handleChange} required />
                  <div className="invalid-tooltip">
                    E-mail Address is Required.
                  </div>
                </div>
                <div className="other">
                  <label htmlFor="other_info">Any other information that you would like to provide</label> <br />
                  <textarea name="otherInfo" id="otherInfo" value={formData.otherInfo} onChange={handleChange}></textarea>
                </div>
                <div className="submit">
                  <input id="submit" name="submit" type="submit" value="Submit" />
                </div>
              </form>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="logo-heading">
              <div className="sqs-block-content">
                <hr />
              </div>
              <Link to={logo} target='blank'>
                <h2>NovaTales Solutions LLP</h2>
              </Link>
              <p className="p1">Visit our company in Hyderabad to take your company to the next level.</p>
              <p className="p2">Endula Empresa, 5th Floor, Survey no:192, Kondapur, Masjid Banda, Hyderabad.</p>
              <div className="map pt-3">
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15223.561365483194!2d78.3459329!3d17.4649619!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb932a0a23c813%3A0x579fff0ed0e66665!2sNova%20Tales%20Media!5e0!3m2!1sen!2sin!4v1706016071329!5m2!1sen!2sin" width="600" height="450" style={{border: 0}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
